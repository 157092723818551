body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
  --grey-25: #fcfdff;
  --grey-50: #f9fafd;
  --grey-100: #f2f5f9;
  --grey-200: #e3e7ef;
  --grey-300: #ccd6e0;
  --grey-400: #97a3b6;
  --grey-500: #677489;
  --grey-600: #4a5567;
  --grey-700: #364152;
  --grey-800: #20283a;
  --grey-900: #11172a;
  --coolGrey-900: #121826;
  --white: #ffffff;
  --blue: #458fff;
  --red: #a93029;
  --main: #458fff;
  --main-light: #fff5e5;
  --red-badge: #f74761;
  --red-badge-background: #fae9e8;
  --green-badge: #1bc5bd;
  --green-badge-background: #e7fffe;
  --grey-badge: #5c7977;
  --grey-badge-background: #edf3f3;
  --green: #64be79;
  --green-light: #e8fbec;
  --digitalu-red: #f71344;
  --digitalu-border: #f8f8f8;
}